@use "./config/" as *;
.waf-statsdetail {
    .waf-body {
        @extend %mt-16;
        @extend %relative;
    }
    .filter-section {
        top: var(--space-12-neg);
        right: 0;
    }
    .card-list {
        @extend %d-none;
    }
    .tab-title {
        @extend %d-none;
    }
    .tab-section {
        top: 10rem;
        width: calc(100% - 6rem);
        @extend %absolute;
        .tab {
            &s {
                @extend %flex;
                @extend %gap-3;
            }
            &-name {
                height: 4.4rem;
                border: .1rem solid var(--secondary-600);
                cursor: pointer;
                @extend %w-100;
                @extend %half-radius;
                @extend %flex-c-c;
                @extend %px-3;
                &.active {
                    border: .1rem solid var(--tertiary-600);
                    font-weight: 700;
                    @extend %tertiary-600-bg;
                    @extend %secondary-800;
                    .text {
                        font-weight: 700;
                    }
                }
                span {
                    width: max-content;
                    @extend %capitalize;
                    @extend %neutral-800;
                    @extend %font-14-pm;
                }
            }
        }
    }
    .table {
        border-bottom: .1rem solid clr(neutral-400, 3);
        @extend %flex;
        @extend %full-radius;
        @extend %hidden;
        .text {
            @extend %neutral-900;
            @extend %font-12;
        }
        &-responsive {
            @extend %transparent-bg;
            .btn-more {
                width: 20rem;
                @extend %my-6;
                @extend %btn-fill;
                @extend %mx-auto;
                @extend %d-block;
                @extend %uppercase;
                &:hover {
                    outline: none;
                    background: var(--tertiary-800);
                    color: var(--neutral-900);
                }
            }
            .table-data {
                @extend %w-100;
            }
        }
        &-left {
            .table-head {
                background: linear-gradient(91.83deg, clr(secondary-800) 0%, clr(primary-700) 100%);
            }
        }
        &-right {
            .table-head .table-data {
                @extend %primary-700-bg;
            }
            .table-data:last-child {
                border-right: .1rem solid clr(neutral-400, 3);
            }
        }
        &-head {
            min-width: unset;
            .table-data {
                height: 4rem;
            }
            .text {
                @extend %pure-white-900;
            }
        }
        &-left {
            width: 80%;
            @extend %pure-white-900-bg;
        }
        &-right {
            width: 20%;
            @extend %pure-white-900-bg;
        }
        &-body {
            .table-row:nth-child(even) .table-data {
                @extend %neutral-200-bg-3;
            }
            .table-row .table-data.active,
            .table-row:nth-child(even) .table-data.active {
                @extend %tertiary-100-bg;
            }
        }
        &-data {
            border-left: .1rem solid clr(neutral-400, 3);
            height: 6.5rem;
            @extend %p-3;
            &.position {
                max-width: 5rem;
                flex-shrink: 0;
            }
            &.label {
                justify-content: flex-start;
                @extend %px-3;
                .image {
                    width: 4.5rem;
                    height: 4.5rem;
                    flex-shrink: 0;
                    border: .1rem solid clr(neutral-400, 3);
                    @extend %mr-2;
                    @extend %p-1;
                    @extend %circle-radius;
                    @extend %neutral-50-bg;
                }
                .table-data-wrapper {
                    @extend %flex-n-c;
                    .name {
                        @extend %d-none;
                    }
                }
            }
            &-text {
                flex: 1;
                @extend %text-left;
            }
            &-wrapper {
                flex: 1;
            }
            &-name {
                width: max-content;
                @extend %font-12-pb;
                @extend %pb-1;
                @extend %mb-1;
            }
            &-team {
                @extend %font-12-pm;
            }
        }
        &-row {
            justify-content: flex-start;
            .runs {
                span {
                    font-weight: 700;
                }
            }
            // &.highlight {
            //     // @extend %primary-500-bg;
            // }
        }
    }
    .no-data {
        @extend %w-100;
        @extend %text-center;
        @extend %p-2;
        @extend %font-14-pb;
    }
}
@media screen and (max-width: $small-mobile-max-width) {
    .waf-statsdetail {
        .tab-section .tab-name {
            padding-inline: var(--space-2);
        }
    }
}
@include mq(col-md) {
    .waf-statsdetail {
        .tab-section {
            top: 12rem;
            width: 50%;
        }
        .table {
            margin-top: var(--space-4);
            &-left {
                width: 50%;
            }
            &-right {
                width: 50%;
            }
            &-data {
                min-width: 6rem;
                width: 100%;
                flex-shrink: unset;
                &.label {
                    width: 100%;
                }
                &.position {
                    max-width: unset;
                    width: 6rem;
                }
                &-name {
                    font-size: 1.4rem;
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-statsdetail {
        .table {
            &-left {
                width: 40%;
            }
            &-right {
                width: 60%;
            }
        }
    }
}